import React from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"

import "../../styles/frontend.scss"
import TechHeader from "../../images/header-za-tehnologiju.png";

import 'toasted-notes/src/styles.css';
import CareerForm from '../../components/Career/CareerForm'
import Headline from '../../components/Career/Headline'
import List from '../../components/Career/List'
import ReasonToJoinUs from '../../components/Career/ReasonToJoinUs'

export default class AndroidDeveloper extends React.Component {

    render() {

        return (
            <Layout>
                <SEO title="Android developer" keywords={[`S7 Design`, `coding`, `develop`,'Android SDK', 'web development']} />
                <div className="frontend-main" style={{ backgroundImage: `url(${TechHeader})` }}>
                    <div className="container">
                        <Headline title={'Android developer'} />
                        <div className="row frontend-main--skills">
                         
                            <div className="col-md-6 col-12">
                            <List 
                               title={"You are excellent candidate if you:"}
                               items={[
                                    'minimum 1+ year of experience',
                                    'good knowledge of Java',
                                    'understanding Andorid SDK',
                                    'knowledge of XML and JSON',
                                    'understanding of REST API and use in development of Andorid',
                                    'Database understanding (MySQL, MSSQL, SQLlite)',
                                    'good understanding of material design',
                                    'knowledge of Git',
                                    'have good verbal and written communication skills on English',
                                ]}
                            />	
                            <List 
                               title={"Position Type:"}
                               items={[
                                    'full time employment'
                                ]}
                            />	
                            </div>
                            <div className="checked col-md-6 col-12">
                            <List 
                               title={"What we offer:"}
                               items={[
                                    'opportunity to work in a young, constructive and agile team',
                                    'competitive salary',
                                    'learning and development opportunities',
                                ]}
                            />	
                            </div>
                            <div className="w-100 height"></div>
                            <ReasonToJoinUs />
                            <div className=" offset-md-1 col-md-10 col-12">
                                <CareerForm contactTitle="Android developer"/>
                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
        )

    }
}
